import { getOrders } from "api/getOrders";
import { EmptyOrders } from "components/layout/emptyOrders";
import { Header } from "components/common/header";
import { ProductLayout } from "components/pages/orders/components/productLayout";
import { Order } from "models/order";
import { useEffect, useState } from "react";
import Button from "components/common/button/button";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { TextField } from "components/common/textField";
import { useNavigate, useLocation } from "react-router-dom";
import { SortButton } from "components/common/button/sortButton";
import ShimmerEffect from "components/common/loader/ShimmerEffect";
import { useToast } from "components/common/toast/toastService";
import { useAuth } from "context/authContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { Option } from "models/options";
import { getSortOptions } from "utils/getSortOptions";

export const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortSelected, setSortSelected] = useState<Option>({
    name: "New to old",
    value: "creationTime desc",
    field: "creationTime",
    isDescending: true,
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const toast = useToast();
  const { isLoggedIn } = useAuth();

  const orderSortOptions = getSortOptions("orders");

  useEffect(() => {
    if (location.state && location.state.showToast) {
      toast.open(
        "Payment Processing",
        "Your payment is in processing. You can see your orders here and check later.",
        "info",
        5000
      );
    }
  }, [location.state]);

  useEffect(() => {
    if (isLoggedIn) {
      setPage(0);
      setOrders([]);
      setHasMore(true);
      loadOrders(true);
    }
  }, [isLoggedIn, searchTerm, sortSelected]);

  const loadOrders = async (isNewSearch = false) => {
    try {
      if (isNewSearch) {
        setInitialLoading(true);
      } else {
        setLoadingMore(true);
      }

      const response = await getOrders({
        skipCount: isNewSearch ? 0 : page * 20,
        maxResultCount: 20,
        ...(searchTerm ? { filter: searchTerm } : null),
        sorting: sortSelected?.field,
        isDescending: sortSelected?.isDescending,
      });

      if (isNewSearch) {
        setOrders(response.items);
      } else {
        setOrders((prevOrders) => [...prevOrders, ...response.items]);
      }

      if (response.items.length < 20) {
        setHasMore(false);
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching orders", error);
    } finally {
      setInitialLoading(false);
      setLoadingMore(false);
    }
  };

  const onExpand = (id: string) => {
    const selectedOrder = orders.find((x) => x.id === id);
    if (selectedOrder) {
      selectedOrder.expanded = !selectedOrder.expanded;
      setOrders((prevState) =>
        prevState.map((order) => (order.id === id ? selectedOrder : order))
      );
    }
  };

  const navigateToHome = () => navigate("/");
  const navigateToLogin = () => navigate("/profile");

  const ShimmerEffectLoader = (
    <div className="p-5 flex flex-col gap-4">
      {Array.from({ length: 5 }).map((_, idx) => (
        <ShimmerEffect
          key={idx}
          width="100%"
          height="120px"
          borderRadius="20px"
          miniLoaderSizeOne="large"
          miniLoaderSizeTwo="medium"
          miniLoaderSizeThree="small"
        />
      ))}
    </div>
  );

  return (
    <div className="w-full text-center pt-1 mb-10">
      <Header headerTitle="Orders" hideNavigation />
      {isLoggedIn ? (
        <>
          <div className="px-1">
            <TextField
              searchIcon
              placeholder="Type OrderId or Brand Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {initialLoading ? (
            ShimmerEffectLoader
          ) : orders.length > 0 ? (
            <>
              <div className="flex justify-end">
                <SortButton
                  setSelected={setSortSelected}
                  selected={sortSelected}
                  options={orderSortOptions}
                />
              </div>
              <div className="flex flex-col overflow-y-auto">
                {orders.map((order, index) => (
                  <div key={`order-${index}`} className="py-1">
                    <ProductLayout order={order} onExpand={onExpand} />
                  </div>
                ))}
              </div>
              {hasMore && (
                <div className="mt-4 flex justify-center">
                  <Button
                    text={loadingMore ? "Loading..." : "Load More"}
                    onClick={() => loadOrders(false)}
                    className="w-32 h-10 text-center"
                  />
                </div>
              )}
              {!hasMore && (
                <div className="mt-4 text-center text-sm text-gray-500">
                  It looks like you've reached the end
                </div>
              )}
            </>
          ) : searchTerm ? (
            <div className="flex justify-center items-center h-[50vh]">
              <div>No matching results</div>
            </div>
          ) : (
            <div className="align-text-bottom mt-20">
              <EmptyOrders className="py-10 px-4" />
            </div>
          )}
          {orders.length < 1 && !searchTerm && !initialLoading && (
            <div className="px-4">
              <Button
                text="Go shopping"
                onClick={navigateToHome}
                icon={<ChevronRightIcon className="text-white h-6 w-6" />}
                className="h-14 text-base font-bold leading-5"
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col justify-center items-center h-full">
          <Button
            text="Login to see your orders"
            onClick={navigateToLogin}
            className="h-14 text-base font-bold leading-5"
          />
        </div>
      )}
      <div className="h-24"></div>
    </div>
  );
};
