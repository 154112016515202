import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "components/common/toast/toastService";
import { validateLoggedInStatus } from "api/validateLoggedInStatus";
import { useAuth } from "context/authContext";

interface RouteValidatorProps {
  children: React.ReactNode;
}

const RouteValidator: React.FC<RouteValidatorProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const protectedPaths = [
    "/wishlist",
    "/checkout",
    "/orders",
    "/profile",
    "/profile/wishlist",
    "/profile/settings",
    "/orders/:id",
    "/oauth/binance/callback",
    "/verify-payment",
  ];

  useEffect(() => {
    const validateSession = async () => {
      const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";

      if (!isLoggedIn && !storedIsLoggedIn) {
        return;
      }

      try {
        const isValid = await validateLoggedInStatus();
        if (!isValid && (isLoggedIn || storedIsLoggedIn)) {
          localStorage.removeItem("isLoggedIn");
          setIsLoggedIn(false);

          const shouldRedirect = protectedPaths.some((path) => {
            const regexPath = new RegExp(`^${path.replace(/:id/g, "[^/]+")}$`);
            return regexPath.test(location.pathname);
          });

          if (shouldRedirect && location.pathname !== "/profile") {
            navigate("/profile", { replace: true });
            toast.open(
              "Session Expired",
              "Your session has expired. Please log in again to continue.",
              "alert",
              10000
            );
          } else {
            toast.open(
              "Session Expired",
              "Your session has expired. Please navigate to profile page, log in again to continue.",
              "alert",
              10000
            );
          }
        } else if (isValid) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Error validating session:", error);
      }
    };

    validateSession();
  }, [location.pathname]);

  return <>{children}</>;
};

export default RouteValidator;
