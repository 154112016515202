import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const validateLoggedInStatus = async (): Promise<boolean> => {
  const response = await apiService.get<boolean>(
    `${API_URL}/app/external-account/logged-in`
  );

  return response.data;
};
