import { routes } from "routes/routes";

export const handleUnauthorizedError = (
  error: any,
  isLoggedIn: boolean,
  setIsLoggedIn: (value: boolean) => void,
  clearCartInLocalStorage: () => void,
  toast: any
) => {
  if (
    error.response?.status === 401 ||
    (error.response?.status === 302 && isLoggedIn)
  ) {
    console.error("Unauthorized error, redirecting to profile...");
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    clearCartInLocalStorage();
    toast.open("Session Expired", "Please log in again.", "error", 5000);
    window.location.href = routes.profile;
    return true;
  }
  return false;
};
