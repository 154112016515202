import { NavigationCard } from "components/common/navigationCard";
import { Faq } from "components/common/faq";
import { Header } from "components/common/header";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";

export const Help = () => {
  const navigate = useNavigate();

  const onNavigateBack = () => navigate(routes.profile);

  const onGoBack = () => {
    onNavigateBack();
  };

  const openWidgetToSupport = useCallback(() => {
    window.zE("webWidget", "show");
    window.zE("webWidget", "open");
  }, []);

  return (
    <>
      <Header headerTitle="FAQ" hideMiniCart onNavigateBack={onGoBack} />

      <div className="scrollable-section">
        <div className="text-left">
          <Faq
            title="How am I going to receive the codes?"
            detail="Upon successful creation and payment of the order, the customer will see the code directly on the order details page. The code will also be sent to the account holder's registered email address."
          />
          <Faq
            title="How to report an issue for an order?"
            detail="Customers can send us an email to support@tamana.app with the order number. Our support will respond to your complaints within 24 - 48 hours."
          />
          <Faq
            title="Can I refund my order?"
            detail="Once the order is placed and the code is sent to the account holder's registered email, refunds are not permitted."
          />
          <Faq
            title="Where can I find redemption information for a brand?"
            detail="Information related to a brand, including redemption instructions and terms and conditions, can be found either on the brand page or in the order detail page after payment is completed successfully."
          />
        </div>

        <div className="text-left ml-2 mt-3 text-base font-medium text-primary">
          Need more help?
        </div>

        <NavigationCard
          label="Contact Us"
          boldTitle
          onNavigate={openWidgetToSupport}
          className="mt-4 font-medium"
        />
      </div>
    </>
  );
};
