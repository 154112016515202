import validator from "validator";
import { getOrderById } from "api/getOrderDetail";
import { Ratings } from "components/layout/ratings";
import { Header } from "components/common/header";
import { InfoWithLogoCard } from "components/layout/infoWithLogoCard";
import { Order, OrderCode } from "models/order";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InfoList } from "components/common/infoList";
import { Badge } from "components/common/badge";
import ReactCountryFlag from "react-country-flag";
import { getStatusType } from "utils/getStatusType";
import { dateFormatterWithTime } from "utils/dateUtils";
import { ShowCode } from "components/layout/showCode";
import { Modal } from "components/common/modal";
import { CopyCode } from "components/layout/copyCode";
import { routes } from "routes/routes";
import { useToast } from "components/common/toast/toastService";
import { getCardCode } from "api/getCardCode";
import { Code } from "models/orderCode";
import {
  DEFAULT_PRICE_FIXED_DECIMALS,
  MAIN_CRYPTO_CURRENCY,
  ORDER_STATUSES,
  USD_TO_USDT,
} from "utils/constants";
import { removeFromWishlist } from "api/removeFromWishlist";
import { addToWishList } from "api/addToWishlist";
import CopyButton from "components/common/CopyButton";
import Button from "components/common/button/button";
import { addToBasket } from "api/addToBasket";
import { AppContext } from "context/appContext";
import ProductDetailPageLoader from "components/pages/productDetail/loader/ProductDetailPageLoader";
import { sendOrderDetails } from "api/sendOrderDetails";
import { sendOrderDetailsByOrderId } from "api/sendOrderDetailsByOrderId"
import { useErrorBoundary } from "utils/handleErrorBoundary";

export const OrderDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();

  const [isInWishlist, setIsInWishlist] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const [rating, setRating] = useState(0);
  const [isOpenCode, setIsOpenCode] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<string>();
  const [selectedCode, setSelectedCode] = useState<Code | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [clientEmail, setClientEmail] = useState<string>("");
  const [sendFullDetails, setSendFullDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { fetchCart } = useContext(AppContext);
  const [isCartUpdated] = useState(false);
  const { setCart } = useContext(AppContext);
  const [emailError, setEmailError] = useState<string | null>(null);
  const location = useLocation();
  const handleErrorBoundary = useErrorBoundary();

  useEffect(() => {
    if (order?.isInWishlist) {
      setIsInWishlist(order.isInWishlist);
    }
  }, [order]);

  useEffect(() => {
    if (!loading && !order) {
      navigate(routes.orders);
    }
  }, [loading, order, navigate]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const orders = await getOrderById(id);
          setOrder(orders || null);
          setClientEmail(orders.clientEmail);
        } catch (error) {
          console.error("Error fetching order details:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    // Reset scroll position to top when navigating to OrderDetail
    if (location.pathname.includes(routes.orders)) {
      window.scrollTo(0, 0); // Scroll to top
    }
  }, [location.pathname]); 

  const onOpenCode = async (orderItem: OrderCode, product: string) => {
    try {
      const cardCode = await getCardCode(orderItem.id);
      setSelectedCode(cardCode);
      setSelectedProduct(product);
      setIsOpenCode(true);
    } catch (error) {
      toast.open("Error", "Failed to fetch code", "error", 5000);
    }
  };

  const onCopyCode = (text: string, isOrderId?: boolean) => {
    navigator.clipboard.writeText(text);
    setIsOpenCode(false);
    toast.open(
      "Copied",
      `${isOrderId ? "Order ID" : "Code"} copied to clipboard`,
      "info",
      2000
    );
  };

  const onProceed = () => {
    if (selectedCode) {
      onCopyCode(selectedCode.code);
    }
    setIsOpenCode(false);
  };

  const onAddToFavorite = async () => {
    setIsInWishlist(!isInWishlist);
    try {
      if (isInWishlist) {
        await removeFromWishlist(order?.brandId || "");
      } else {
        await addToWishList(order?.brandId || "");
      }
    } catch (error) {
      toast.open(
        "Error",
        `Failed to ${isInWishlist ? "Remove from" : "Add to"} Wishlist`,
        "error",
        5000
      );
      console.error("Error adding to wishlist", error);
    }
  };

  const handleOrderAgain = async () => {
    try {
      if (order?.orderItems && order.orderItems.length > 0) {
        const orderItem = order.orderItems[0];

        const data = {
          unitFaceValue: orderItem.unitFaceValue,
          quantity: orderItem.quantity,
          unitPrice: orderItem.unitPrice,
          productId: orderItem.productId,
        };

        await addToBasket(data);
        const updatedCart = await fetchCart();
        setCart(updatedCart);
        navigate(routes.checkout);
        toast.open("Success", "Added to basket", "success", 5000);
      }
    } catch (error) {
      console.error("Error adding to basket", error);
      handleErrorBoundary(error);
    }
  };

  useEffect(() => {
    if (isCartUpdated) {
      navigate(routes.checkout);
      toast.open("Success", "Added to basket", "success", 5000);
    }
  }, [isCartUpdated, navigate, toast]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setClientEmail(newEmail);

    if (newEmail && !validator.isEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(null);
    }
  };

  const handleResendDetails = async () => {
    try {
      if (order) {
        if (sendFullDetails) {
          await sendOrderDetails(order.orderId, clientEmail);
          setIsOpen(false);
        } else {
          await sendOrderDetailsByOrderId(order.id, clientEmail);
          setIsOpen(false);
        }
        toast.open("Success", "Details sent successfully", "success", 5000);
      } else {
        throw new Error("Order details are not available.");
      }
    } catch (error) {
      console.error("Error sending details:", error);
      toast.open("Error", "Failed to send order details.", "error", 5000);
    } finally {
      setIsOpen(false);
    }
  };

  const shouldShowSuccessButton = () => {
    return (
      order?.orderStatus.toString() === ORDER_STATUSES.SUCCEEDED ||
      order?.orderStatus.toString() === ORDER_STATUSES.PARTIALFAILED
    );
  };

  return (
    <div className="h-screen pt-1">
      <Header
        headerTitle="Orders"
        // onNavigateBack={() => navigate(routes.orders)}
        onNavigateBack={() => navigate(-1)}
      />

      {loading && <ProductDetailPageLoader />}

      {!loading && order && (
        <>
          <InfoWithLogoCard
            name={order.brandName || ""}
            logoUrl={order.brandLogoUrl || ""}
            isInWishlist={isInWishlist}
            onAddToFavorite={onAddToFavorite}
          />

          <div className="flex justify-center">
            <div className="flex">
              <Ratings starsCount={5} rating={rating} setRating={setRating} />
            </div>
            <div className="mt-3 text-xs font-semibold text-primary-blue ml-1">
              Leave a review
            </div>
          </div>

          <div className="mt-6 text-left text-sm font-medium text-primary">
            Details
          </div>

          <div className="pt-3">
            <div className="p-2 shadow-card rounded-lg mt-10">
              <InfoList
                label="Status"
                value={
                  <Badge
                    text={order.orderStatus.toString()}
                    type={getStatusType(order.orderStatus.toString() || "")}
                  />
                }
              />
              <InfoList
                label="Location"
                value={
                  <div className="flex flex-row gap-1 align-middle">
                    <ReactCountryFlag
                      countryCode={order.brandCountryCode || ""}
                      svg
                      className="rounded-sm"
                    />
                    <div className="-mt-0.5">{order.brandCountryCode}</div>
                  </div>
                }
              />
              <InfoList label="Method" value={order.deliveryMethod} />
              <InfoList
                label="Order ID"
                value={
                  <div className="flex rounded-large bg-selected px-2 py-1 w-60">
                    <div className="col-span-11 text-sm font-normal text-primary truncate text-ellipsis">
                      {order.orderId}
                    </div>
                    <CopyButton textToCopy={order.orderId} />
                  </div>
                }
              />
              <InfoList
                label="Date and time"
                value={dateFormatterWithTime(order.creationTime || "")}
              />
            </div>
          </div>

          <div className="mt-4 text-left text-sm font-medium text-primary">
            Receiver
          </div>
          <div className="pt-4">
            <div className="p-2 shadow-card rounded-lg mt-2">
              <InfoList label="Email" value={order.clientEmail} />
            </div>
          </div>

          <div className="flex">
            {order.orderItems &&
              order.orderItems.map((orderItem) => {
                return (
                  <div className="flex flex-row w-full justify-between p-1 items-center mt-2">
                    <div className="text-sm font-normal">
                      {orderItem.productName}
                    </div>
                    <div className="text-sm font-normal">
                      {`| X${orderItem.quantity}`}{" "}
                    </div>
                  </div>
                );
              })}
          </div>

          {shouldShowSuccessButton() && (
            <div className="mt-7">
              {order.orderItems &&
                order.orderItems.map((orderItem, index) => {
                  return (
                    <ShowCode
                      key={`code-${index}`}
                      codes={orderItem.orderItemCards}
                      title={orderItem?.productName}
                      onSelectCode={onOpenCode}
                    />
                  );
                })}
            </div>
          )}

          {selectedCode && (
            <Modal
              isOpen={isOpenCode}
              setIsOpen={setIsOpenCode}
              title={selectedProduct ?? ""}
              content={<CopyCode code={selectedCode} onCopyCode={onCopyCode} />}
              onProceed={onProceed}
              cancelText="Close"
              proceedText="Copy"
            />
          )}

          <div className="mt-2 px-2">
            <div className="flex justify-between p-1 text-lg font-medium text-primary">
              <span>Total:</span>
              <div>
                {order.totalItemPrice ?? 0} {MAIN_CRYPTO_CURRENCY}
              </div>
            </div>
            <div className="-mt-1 pr-1">
              <div className="text-xs font-medium text-right">
                {order.totalItemPrice
                  ? (order.totalItemPrice / USD_TO_USDT).toFixed(
                      DEFAULT_PRICE_FIXED_DECIMALS
                    )
                  : 0}{" "}
                USD
              </div>
            </div>

            <div className="flex mt-4 pb-24 text-right mr-1">
              {shouldShowSuccessButton() && (
                <Button
                  className="w-1/2 mr-2"
                  text="Send details"
                  onClick={() => setIsOpen(true)}
                />
              )}

              <Button
                className="w-1/2"
                text="Order again"
                onClick={handleOrderAgain}
              />
            </div>

            <Modal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title="Please confirm the details"
              content={
                <div className="py-2 text-xs font-normal text-primary">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    value={clientEmail}
                    onChange={handleEmailChange}
                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {emailError && (
                    <p className="text-red-500 text-xs mt-1">{emailError}</p>
                  )}
                  <div className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      checked={sendFullDetails}
                      onChange={(e) => setSendFullDetails(e.target.checked)}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Send full details
                    </label>
                  </div>
                </div>
              }
              onProceed={handleResendDetails}
              cancelText="Cancel"
              proceedText="Send"
            />
          </div>
        </>
      )}
    </div>
  );
};
