import React, {
  Fragment,
  useRef,
  useState,
  InputHTMLAttributes,
  useEffect,
} from "react";
import { Listbox, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Option } from "models/options";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  options: Option[];
  selectedValue: Option | null;
  onChangeSelect: (option: Option) => void;
  showRadioButtons?: boolean;
  showCustomSelectedValue?: string;
  isCartItemSelect?: boolean;
  className?: string;
  optionClassName?: string;
}

export const Select: React.FC<InputProps> = ({
  options,
  selectedValue,
  onChangeSelect,
  showRadioButtons = false,
  showCustomSelectedValue,
  isCartItemSelect,
  className,
  optionClassName
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOpenDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleOptionSelect = (option: Option) => {
    onChangeSelect(option);
    setDropdownOpen(false);
  };


  const renderDropdown = () => {
    if (!buttonRef.current || !dropdownOpen) return null;

    const dropdownPosition = buttonRef.current.getBoundingClientRect();

    return createPortal(
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={dropdownOpen}
      >
        <Listbox.Options
          className="z-[9999] absolute max-h-60 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          style={{
            top: dropdownPosition.bottom + window.scrollY,
            left: dropdownPosition.left + window.scrollX,
            width: dropdownPosition.width,
          }}
        >
          {options.length > 0 ? (
            options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={`relative cursor-default select-none py-2 mx-2 rounded-2xl ${selectedValue?.value === option.value
                    ? "bg-option-border py-2"
                    : "text-option-text"
                  } ${showRadioButtons ? "pr-4" : "p-0"} ${optionClassName || ""}`}
                value={option}
                onClick={() => handleOptionSelect(option)}
              >
                <span
                  className={`flex w-full truncate ${selectedValue?.value === option.value
                      ? "font-medium"
                      : "font-normal"
                    } ${!showRadioButtons && "justify-center"}`}
                >
                  <span className={`${showRadioButtons ? "px-3" : "px-0"}`}>
                    {showRadioButtons && (
                      <input
                        type="radio"
                        checked={selectedValue?.value === option.value}
                        className="h-4 w-4 checked:bg-btn-to text-btn-to"
                      />
                    )}
                  </span>
                  {selectedValue?.value === option.value
                    ? showCustomSelectedValue ?? option.name
                    : option.name}
                </span>
              </Listbox.Option>
            ))
          ) : (
            <Listbox.Option value={null} className="py-1">
              <span className="text-input-label">No options available</span>
            </Listbox.Option>
          )}
        </Listbox.Options>
      </Transition>,
      document.body
    );
  };

  return (
    <Listbox value={selectedValue} onChange={onChangeSelect}>
      <div className={`${isCartItemSelect ? "h-6" : "h-11"} relative mt-1`}>
        <Listbox.Button
          ref={buttonRef}
          className={`${className ? className : ""} ${isCartItemSelect ? "h-6 py-2 w-20" : "h-11 py-4 w-full"
            } relative cursor-default rounded-2xl focus:border-primary-blue border border-input-border bg-white pl-3 pr-10 text-center shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 text-sm`}
          onClick={handleOpenDropdown}
        >
          <span className="block truncate relative top-[-6px]">
            {selectedValue?.name}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-input-icon"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        {renderDropdown()}
      </div>
    </Listbox>
  );
};
