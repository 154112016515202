import { useToast } from "components/common/toast/toastService";

export const useErrorBoundary = () => {
  const toast = useToast();

  return (error: any) => {
    console.error("Error caught:", error);

    const errorCode = error?.response?.status || "Unknown Error Code";
    const errorMessage =
      error?.response?.data?.error?.details ||
      error?.response?.data?.error?.message ||
      "Something went wrong.";

    const userMessage = `${errorMessage} ${
      error?.response?.data?.error?.details
        ? ""
        : " If the issue persists, please contact our support team from the Help section"
    }.`;
    console.error(`Error Code: ${errorCode}, Message: ${errorMessage}`);

    toast.open("Error", userMessage, "error", 5000);

    return userMessage;
  };
};
